import React, {useEffect, useState} from 'react'
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {checkUsername, getProfile, signUp} from "../services/UserService";
import '../stylesheets/Login.css';

const OauthLanding = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [finishSignup, setFinishSignup] = useState(false);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [usernameError, setUsernameError] = useState(null);
    const state = searchParams.get("state");
    const usernameRegex = /^\w+$/;


    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((response) => {
                localStorage.setItem('token', response.getSignInUserSession().getIdToken().getJwtToken())
                getProfile(response.getSignInUserSession().getIdToken().getJwtToken())
                    .then((user) => {
                        if (user.status == 200) {
                            localStorage.setItem('user', JSON.stringify(user.data))
                            navigate('/')
                        }
                    })
                    .catch((error) => {
                        setEmail(response.attributes.email);
                        setFinishSignup(true);
                    });
            });
    }, [])

    const createUser = (event) => {
        event.preventDefault();
        if (username && !username.match(usernameRegex)) {
            setUsernameError("Username must only contain letters, numbers, and underscores");
        } else {
            checkUsername(username)
                .then(resp => {
                    if (resp.status === 200 && resp.data && resp.data.available) {
                        setUsernameError(null);
                        let user = {};
                        user["username"] = username
                        user["first_name"] = firstName
                        user["last_name"] = lastName
                        user["email"] = email
                        user["user_type"] = "content_creator"
                        signUp(localStorage.getItem("token"), user)
                            .then(response => {
                                localStorage.setItem('user', JSON.stringify(response.data))
                                navigate('/');
                            })
                    } else {
                        setUsernameError("Username is already taken");
                    }
                });
        }
    }

    if (finishSignup) {
        return (
            <div>
                <div className="w-50 d-inline-block">
                    <img alt="" className="signup-img" src="https://dupe-photos.s3.amazonaws.com/signup.png"/>
                </div>
                <div className="w-50 d-inline-block">
                    <div className="w-50 mx-auto login-div">
                        <h3>Finish Signing Up</h3>
                        <h5>For Google account: {email}</h5>
                        <form className="row g-3" >
                            <div className="col-md-6">
                                <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name"/>
                            </div>
                            <div className="col-md-6">
                                <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name"/>
                            </div>
                            <div className="col-12">
                                <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username"/>
                                {usernameError && (
                                    <p className={"username-error-p"}>{usernameError}</p>
                                )}
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary login-page-btn" type="submit" onClick={createUser}>Create New Account</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


export default OauthLanding

import React, {useState} from 'react'
import { Auth } from 'aws-amplify';
import {Link, useNavigate} from 'react-router-dom';
import {getProfile} from "../services/UserService";
import '../stylesheets/Login.css';
import openEye from "../icons/DupeIcons_Eye-Open.png";
import closedEye from "../icons/DupeIcons_Eye-Closed.png";


const Dating = () => {
    const navigate = useNavigate();

    function goHome() {
        navigate('/home');
    }


    return(
        <div className={"datingDiv"}>
            <img className={"datingImg"} src={"https://dupe-photos.s3.amazonaws.com/APRIL+FOOLS+(1080+x+1500+px)+(1080+x+1800+px)+(1).svg"}/>
            <button className={"datingButton font-bold"} onClick={goHome}>SHOW ME THE SICK PICS</button>
        </div>

    );
}

export default Dating

import React, {useEffect, useState} from 'react'
import "../stylesheets/AestheticPage.css";


const AestheticPage = () => {

    return(
        <div className="upload-background">
        </div>
    );
}

export default AestheticPage

import './App.css';
import {Routes, Route, Link} from "react-router-dom";
import Discovery from './components/Discovery'
import NavBar from './components/NavBar'
import SignUp from './components/SignUp'
import {Amplify, Auth} from 'aws-amplify';
import awsconfig from './aws-exports';
import Profile from "./components/Profile";
import OauthLanding from "./components/OauthLanding";
import LandingPage from "./components/LandingPage";
import ForgotPassword from "./components/ForgotPassword";
import LoginPage from "./components/LoginPage";
import UploadPage from "./components/UploadPage";
import AestheticPage from "./components/AestheticPage";
import TrendingAestheticPage from "./components/TrendingAestheticPage";
import SearchPage from "./components/SearchPage";
import AboutUs from "./components/AboutUs";
import HireSignup from "./components/HireSignup";
import LicenseTerms from "./components/LicenseTerms";
import ReactGA from "react-ga4";
import {useEffect} from "react";
import Dating from "./components/Dating";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Support from "./components/Support";
import DeleteAccount from "./components/DeleteAccount";
import CreatorBizSignUp from "./components/CreatorBizSignUp";
import {Toaster} from "react-hot-toast";


export default function App() {

    if (window.location.hostname !== "localhost") {
        awsconfig.oauth.redirectSignIn = "https://dupephotos.com/oauth/"
        awsconfig.oauth.redirectSignOut = "https://dupephotos.com/logout/"
    }
    Amplify.configure(awsconfig);
    Auth.configure(awsconfig);
    const TRACKING_ID = "G-5760W2819H";
    ReactGA.initialize(TRACKING_ID);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
    }, []);

    return (
        <div>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/" element={<NavBar/>}>
                    <Route path="home" element={<Discovery landingPage={false}/>}/>
                    <Route path="collections" element={<Collections/>}/>
                    <Route path="upload" element={<UploadPage/>}/>
                    <Route path="profile" element={<Profile/>}/>
                    <Route path="profile/:username" element={<Profile/>}/>
                    <Route path="aesthetics" element={<AestheticPage/>}/>
                    <Route path="aesthetics/trending" element={<TrendingAestheticPage/>}/>
                    <Route path="results" element={<SearchPage/>}/>
                    <Route path="about-us" element={<AboutUs/>}/>
                    <Route path="dating" element={<Dating/>}/>
                    <Route path="creators" element={<HireSignup/>}/>
                    <Route path="license-and-terms" element={<LicenseTerms/>}/>
                    <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="support" element={<Support/>}/>
                    <Route path="delete-account" element={<DeleteAccount/>}/>
                    <Route path="creator-signup" element={<CreatorBizSignUp/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Route>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/forgot_password" element={<ForgotPassword/>}/>
                <Route path="/oauth" element={<OauthLanding/>}/>
            </Routes>
            <Toaster/>
        </div>
    );
}

function Collections() {
    return (
        <div>
            <h2>Collections</h2>
        </div>
    );
}


function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}

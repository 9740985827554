import React, {useState} from 'react'
import { Auth } from 'aws-amplify';
import {Link, useNavigate} from 'react-router-dom';
import {getProfile} from "../services/UserService";
import '../stylesheets/Login.css';
import openEye from "../icons/DupeIcons_Eye-Open.png";
import closedEye from "../icons/DupeIcons_Eye-Closed.png";


const Login = ({successfulLoginCallback, message}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const googleLoginUrl = getGoogleLoginUrl();

    const createUser = (event) => {
        event.preventDefault();
        setShowError(false);
        if (email && password) {
            Auth.signIn({
                password: password,
                username: email
            })
                .then((result) => {
                    getProfile(result.getSignInUserSession().getIdToken().getJwtToken())
                        .then((user) => {
                            localStorage.setItem('user', JSON.stringify(user.data))
                            localStorage.setItem('token', result.getSignInUserSession().getIdToken().getJwtToken())
                            successfulLoginCallback();
                        })
                })
                .catch(err => {
                    setShowError(true);
                });
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    function getGoogleLoginUrl() {
        var base = "https://auth.dupephotos.com/oauth2/authorize?identity_provider=Google&response_type=code&client_id=3amaur3hqgljj17sfd00bprn05&redirect_uri="
        if (window.location.hostname === "localhost") {
            return base + "http://localhost:3000/oauth/"
        }
        return base + "https://dupephotos.com/oauth/"
    }

    return(
        <div className="w-50 mx-auto login-div">
            {message && (
                <div>
                    <h3 className={"blue-color font-bold"}>Login</h3>
                    <p>{message}</p>
                </div>
            )}
            {!message && (
                <h3 className={"font-bold"}>Good to see you again!</h3>
            )}
            <p className={"d-inline-block"}>Don't have an account?&nbsp;&nbsp;</p>
            <Link to="/signup">
                <p className={"d-inline-block"}>Sign-up</p>
            </Link>
            <a href={googleLoginUrl}>
                <img className={"google-logo"} src={"https://dupe-photos.s3.amazonaws.com/btn_google_signin_light_normal_web%402x.png"} />
            </a>
            <p>OR</p>
            {showError &&
            <p className={"text-danger"}>Email or Password is incorrect</p>
            }
            <form className="row g-3" >
                <div className="col-12">
                    <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                </div>
                <div className="col-md-12 password-div">
                    <input type={showPassword ? "text" : "password"} className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>
                    {showPassword &&
                    <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye} />
                    }
                    {!showPassword &&
                    <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye} />
                    }
                </div>
                <div className="col-12">
                    <button className="btn btn-primary login-page-btn" type="submit" onClick={createUser}>Login</button>
                </div>
                <div className="col-md-12">
                    <Link to="/forgot_password">
                        <p>Forgot Password?</p>
                    </Link>
                </div>
            </form>
        </div>

    );
}

export default Login

import React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom';
import '../stylesheets/Login.css';
import Login from "./Login";
import { Flex, Image } from "rebass";


const LoginPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const loginCallback = () => {
        const verificationCode = searchParams.get("vcode");
        if (verificationCode) {
            navigate('/profile?vcode=' + verificationCode);
        } else {
            navigate('/');
        }
    }

    return(
        <Flex flexDirection="row" height="100vh">
            <Flex>
                <Image height="100vh"
                        sx={{
                            display: 'block',
                            '@media screen and (max-width: 71em)': {
                              display: 'none',
                            }
                          }}
                        width={"100%"}
                        className={"justified-image"}
                        src="https://dupe-photos.s3.amazonaws.com/Login_Return.jpg"/>
            </Flex>
            <Flex alignItems="center">
                <Login successfulLoginCallback={loginCallback} />
            </Flex>
        </Flex>

    );
}

export default LoginPage

import axios from "axios";

export async function getNotifications(token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/notifications', { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function readNotifications(token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/notifications/read', {}, { headers: {'X-CLIENT-ID': token}})
    return result;
}

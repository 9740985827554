import React, {useState} from 'react'
import { Auth } from 'aws-amplify';
import {Link, useNavigate} from 'react-router-dom';
import { Hub } from 'aws-amplify';
import {checkUsername, signUp} from "../services/UserService";
import '../stylesheets/SignUp.css';
import closedEye from "../icons/DupeIcons_Eye-Closed.png";
import openEye from "../icons/DupeIcons_Eye-Open.png";
import { Flex, Image, Text, Box } from "rebass";


const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const navigate = useNavigate();
    const googleLoginUrl = getGoogleLoginUrl();

    const createUser = (event) => {
        event.preventDefault();
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        const usernameRegex = /^\w+$/;
        if (password && password !== confirmPassword) {
            setValidationError("Passwords must match");
        } else if (email && !email.match(validRegex)) {
            setValidationError("Please enter a valid email");
        } else if (password && !password.match(passwordRegex)) {
            setValidationError("Password must be at least 8 characters long and contain at least one upper case and lower case letter, one number, and one special character: !@#$%^&*()");
        } else if (username && !username.match(usernameRegex)) {
            setValidationError("Username must only contain letters, numbers, and underscores");
        } else if (email && password) {
            checkUsername(username)
                .then(resp => {
                    if (resp.status === 200 && resp.data && resp.data.available) {
                        Auth.signUp({
                            password: password,
                            username: email,
                            attributes: {
                                preferred_username: username,
                                given_name: firstName,
                                family_name: lastName
                            },
                            autoSignIn: { // optional - enables auto sign in after user is confirmed
                                enabled: true,
                            }
                        })
                            .then((result) => {
                                listenToAutoSignInEvent()
                            })
                            .catch(err => {
                                if (err.code === "UsernameExistsException") {
                                    setValidationError("That email already has an account.");
                                }
                            });
                    } else {
                        setValidationError("That username is already taken.");
                    }
                })
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    function triggerUsernameCheck() {
        checkUsername(username)
            .then(resp => {
                if (resp.status === 200 && resp.data && resp.data.available === true) {
                    setValidationError(null);
                } else {
                    setValidationError("That username is already taken.");
                }
            });
    }

    function getGoogleLoginUrl() {
        var base = "https://auth.dupephotos.com/oauth2/authorize?identity_provider=Google&response_type=code&client_id=3amaur3hqgljj17sfd00bprn05&redirect_uri="
        if (window.location.hostname === "localhost") {
            return base + "http://localhost:3000/oauth/"
        }
        return base + "https://dupephotos.com/oauth/"
    }

    function listenToAutoSignInEvent() {
        Hub.listen('auth', ({ payload }) => {
            const { event } = payload;
            if (event === 'autoSignIn') {
                let user = {}
                user["username"] = username
                user["first_name"] = firstName
                user["last_name"] = lastName
                user["email"] = email
                user["user_type"] = "content_creator"
                signUp(payload.data.signInUserSession.getIdToken().getJwtToken(), user)
                    .then(response => {
                        localStorage.setItem('user', JSON.stringify(response.data))
                        localStorage.setItem('token', payload.data.signInUserSession.getIdToken().getJwtToken())
                        Auth.verifyCurrentUserAttribute('email')
                            .then(resp => {
                            });
                        navigate('/');
                    })
            } else if (event === 'autoSignIn_failure') {
                // redirect to sign in page
                navigate('/login');
            }
        })
    }

    return(
        <Flex flexDirection="row" height="100vh" width="100%">
            <Image height="100vh"
                    width={1}
                    sx={{
                        display: 'block',
                        '@media screen and (max-width: 71em)': {
                          display: 'none',
                        }
                      }}
                   className={"justified-image"}
                    src="https://dupe-photos.s3.amazonaws.com/sign-up.jpeg"/>
            <Flex flexDirection="column" alignItems="center" height="100vh" width={1} justifyContent="center">
                    <Text fontWeight="700" fontSize="1.75em">Join Our Community</Text>
                    <Flex>
                        <Text>Have an account?&nbsp;</Text>
                        <Link to="/login">
                            <Text>Login</Text>
                        </Link>
                    </Flex>
                    <Flex width="75%" maxWidth="200px">
                        <a href={googleLoginUrl}>
                            <Image src={"https://dupe-photos.s3.amazonaws.com/btn_google_signin_light_normal_web%402x.png"} />
                        </a>
                    </Flex>
                    <Text>OR</Text>
                    <Box as="form" width="75%">
                        <Flex flexDirection="row" width={1}>
                            <Flex padding="1%" width={1}>
                                <input type="text" className="form-control" id="firstName" value={firstName} maxLength="20" onChange={(e) => setFirstName(e.target.value)} placeholder="First Name"/>
                            </Flex>
                            <Flex padding="1%" width={1}>
                                <input type="text" className="form-control" id="lastName" value={lastName} maxLength="20" onChange={(e) => setLastName(e.target.value)} placeholder="Last Name"/>
                            </Flex>
                        </Flex>
                        <Flex padding="1%">
                            <input type="text" className="form-control" id="email" value={email} maxLength="50" onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                        </Flex>
                        <Flex padding="1%">
                            <input type="text" className="form-control" id="username" onBlur={triggerUsernameCheck} value={username} maxLength="25" onChange={(e) => setUsername(e.target.value)} placeholder="Username"/>
                        </Flex>
                        <Flex className={"password-div"} padding="1%" alignItems="center">
                            <input type={showPassword ? "text" : "password"} className="form-control" id="password" maxLength="50" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>
                            {showPassword &&
                            <Image  className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye} />
                            }
                            {!showPassword &&
                            <Image  className={"password-eye-img"} onClick={toggleShowPassword} src={openEye} />
                            }
                        </Flex>
                        <Flex className={"password-div"} padding="1%" alignItems="center">
                            <input type={showPassword ? "text" : "password"} className="form-control" id="confirmPassword" maxLength="50" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password"/>
                            {showPassword &&
                            <Image className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye} />
                            }
                            {!showPassword &&
                            <Image className={"password-eye-img"} onClick={toggleShowPassword} src={openEye} />
                            }
                        </Flex>
                        {validationError &&
                        <Flex padding="1%">
                            <Text className={"validation-error"}>{validationError}</Text>
                        </Flex>
                        }
                        <Flex padding="1%">
                            <button className="btn btn-primary login-page-btn" type="submit" onClick={createUser}>Create New Account</button>
                        </Flex>
                    </Box>
            </Flex>
        </Flex>

    );
}

export default SignUp

import axios from "axios";

export async function saveCreator(request, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators";
    return await axios.post(url, request, { headers: {'X-CLIENT-ID': token}});
}

export async function getCreatorProfile(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators";
    return await axios.get(url, { headers: {'X-CLIENT-ID': token}});
}

export async function getCreatorCampaigns(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/campaigns/overview";
    return await axios.get(url, { headers: {'X-CLIENT-ID': token}});
}

export async function applyToCampaign(request, campaignId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/campaigns/" + campaignId + "/apply";
    return await axios.post(url, request, { headers: {'X-CLIENT-ID': token}});
}

export async function acceptCampaign(campaignId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/campaigns/" + campaignId + "/accept";
    return await axios.post(url, {},{ headers: {'X-CLIENT-ID': token}});
}

export async function denyCampaign(campaignId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/campaigns/" + campaignId;
    return await axios.delete(url, { headers: {'X-CLIENT-ID': token}});
}

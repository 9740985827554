import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import "../stylesheets/Content.css";
import "../stylesheets/Content.mobile.css";
import {
    createCollection,
    createCollectionEntry, deleteCollectionEntry, deleteContentFromCollection,
    getCollectionPreviews,
    updateCollectionCover
} from "../services/CollectionService";
import Modal from "react-modal";
import {deleteContent, getContentSummary, getDownloadFile, getS3Content, toggleLike} from "../services/ContentService";
import download from "../icons/DupeIcons-06-Download.png";
import pencil from "../icons/pencil.png";
import collectionCover from "../icons/collection-cover.png";
import heartImg from "../icons/DupeIcons-Heart-Green.png";
import whiteHeart from "../icons/DupeIcons-08-Heart.png";
import greenHeart from "../icons/DupeIcons-08-Heart-Green.png";
import viewsImg from "../icons/DupeIcons-View.png";
import defaultProfile from "../icons/default-profile-icon.png";
import ellipsis from "../icons/Ellipsis.png";
import collectionIcon from "../icons/collection.png";
import trashcanIcon from "../icons/trash-can.png";
import backArrowIcon from "../icons/back-arrow.png";
import topArrowIcon from "../icons/up-arrow-icon.png";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Login from "./Login";
import ReactPlayer from 'react-player/lazy'

const Content = ({
                     content,
                     additionalContent,
                     profileFlag,
                     editFunc,
                     retrieveNextContent,
                     ownProfile,
                     portfolioFlag,
                     likeFunc
                 }) => {

    const [images, _setImages] = useState([[], [], [], [], []]);
    const [allContent, setAllContent] = useState([]);
    const [collections, setCollections] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [contentToRemove, setContentToRemove] = useState({});
    const [user, setUser] = useState(null);
    const [addCollectionId, setAddCollectionId] = React.useState("");
    const [contentModal, setContentModal] = React.useState(false);
    const [loginModal, setLoginModal] = React.useState(false);
    const [createCollectionFlag, setCreateCollectionFlag] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [confirmationModal, setConfirmationModal] = React.useState(false);
    const [loginMessage, setLoginMessage] = React.useState(null);
    const [collectionImage, setCollectionImage] = React.useState(null);
    const [collectionName, setCollectionName] = React.useState("");
    const [collectionDescription, setCollectionDescription] = React.useState("");
    const [contentImage, setContentImage] = React.useState("");
    const [contentSummary, setContentSummary] = React.useState("");
    const [collectionEditId, setCollectionEditId] = React.useState("");
    const [collectionSearch, setCollectionSearch] = React.useState("");
    const [style, setStyle] = React.useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [url, setUrl] = React.useState(null);
    const [downloadFileName, setDownloadFileName] = React.useState(null);
    const ref = useRef(null);
    const collectionOptionRef = useRef(null);
    const collectionAddRef = useRef(null);
    const [displyTopButton, setDisplyTopButton] = React.useState(false);
    const displayTopButtonRef = useRef(false);
    const scrollRef = useRef(false);

    const defaultStyle = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    function closeCollectionModal() {
        setAddCollectionId("");
    }

    function closeContentModal() {
        setContentModal(false);
        searchParams.delete("content");
        setSearchParams(searchParams);
    }

    function closeLoginModal() {
        setLoginModal(false);
    }

    function closeConfirmationModal() {
        setContentToRemove({});
        setConfirmationModal(false);
    }

    const onLogin = () => {
        window.location.reload();
    }

    function openLoginModal() {
        setStyle({
            overlay: {
                backgroundColor: 'rgba(24, 47, 96, 0.80)',
                position: 'fixed',
                zIndex: 999
            }
        })
        setLoginModal(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function addToCollection(collectionId) {
        const token = localStorage.getItem("token");
        if (token) {
            let collectionEntry = {}
            collectionEntry["content_id"] = collectionImage.id
            collectionEntry["cover_content"] = false
            createCollectionEntry(collectionId, collectionEntry, token)
                .then(response => {
                    setCreateCollectionFlag(false);
                    setAddCollectionId("");
                })
        }
    }

    function removeFromCollection(collectionId) {
        const token = localStorage.getItem("token");
        if (token) {
            deleteContentFromCollection(collectionId, collectionImage.id, token)
                .then(response => {
                    setCreateCollectionFlag(false);
                    setAddCollectionId("");
                })
        }
    }

    function openContentModal(contentId) {
        if (!searchParams.get("content") && searchParams.get("content") != contentId) {
            searchParams.delete("content");
            searchParams.append("content", contentId);
            setSearchParams(searchParams);
        }
        setStyle({
            overlay: {
                backgroundColor: 'rgba(24, 47, 96, 0.80)',
                position: 'fixed',
                zIndex: 999
            }
        })
        const loggedInUser = localStorage.getItem("user");
        let foundUser = {"id": ""};
        if (loggedInUser) {
            foundUser = JSON.parse(loggedInUser);
        }
        getContentSummary(contentId, foundUser.id)
            .then(response => {
                if (response.status === 200) {
                    setContentSummary(response.data);
                    setContentModal(true);
                }
            })
    }

    function openCollectionsModal(event, img) {
        event.stopPropagation();
        const token = localStorage.getItem("token");
        if (user && user.id) {
            getCollectionPreviews(user.id, token, img.id)
                .then(response => {
                    if (response.status === 200) {
                        setCollections(response.data)
                        setAllCollections(response.data);
                        setCollectionImage(img);
                        setAddCollectionId(img.id);
                        if (response.data && response.data.length > 0) {
                            setCreateCollectionFlag(false);
                        } else {
                            setCreateCollectionFlag(true);
                        }
                    }
                })
        } else {
            setLoginMessage("to add " + img.user + "'s photo to a collection");
            openLoginModal();
        }
    }

    function filterCollections(e) {
        //setCollectionSearch(e.target.value);
        setCollections(allCollections.filter(collection => {
            if (collection.name.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1) {
                return true;
            } else {
                return false;
            }
        }))
    }

    function openCreateCollection() {
        setCollectionName("");
        setCollectionDescription("");
        setCreateCollectionFlag(true);
    }

    function backCollection() {
        setCreateCollectionFlag(false);
    }

    function showCollectionOptions(event, image) {
        event.stopPropagation();
        setCollectionEditId(image.id);
    }

    function makeEntryCover(event, image) {
        event.stopPropagation();
        let req = {};
        req["collection_entry_id"] = image["collection_entry_id"]
        const token = localStorage.getItem("token");
        updateCollectionCover(req, token)
            .then(resp => {
                setCollectionEditId(null);
            })
    }

    function deleteCollectionEntryFunc(event) {
        event.stopPropagation();
        const token = localStorage.getItem("token");
        if (portfolioFlag) {
            deleteContent(contentToRemove["id"], token)
                .then(resp => {
                    setImages(allContent.filter(img => {
                        return img.id !== contentToRemove.id;
                    }));
                    closeConfirmationModal();
                })
        } else {
            deleteCollectionEntry(contentToRemove["collection_entry_id"], token)
                .then(resp => {
                    setImages(allContent.filter(img => {
                        return img.id !== contentToRemove.id;
                    }));
                    closeConfirmationModal();
                })
        }
    }

    function openConfirmationModal(event, image) {
        event.stopPropagation();
        setCollectionEditId(null);
        setContentToRemove(image);
        setConfirmationModal(true);
    }

    function createNewCollection() {
        if (collectionName) {
            const token = localStorage.getItem("token");
            if (token) {
                let collection = {}
                collection["name"] = collectionName;
                collection["description"] = collectionDescription;
                collection["cover_content_id"] = collectionImage.id
                createCollection(collection, token)
                    .then(response => {
                        if (response.status === 200) {
                            setCreateCollectionFlag(false);
                            setAddCollectionId("");
                            setCollectionName("");
                            setCollectionDescription("");
                        }
                    });
            }
        }
    }


    const setImages = (data) => {
        let newImages = [];
        if (windowSize.current[0] < 500) {
            newImages = [[], []];
        } else {
            newImages = [[], [], [], [], []];
        }
        if (data) {
            data.forEach((image, index) => {
                newImages[index % newImages.length].push(image)
            });
            _setImages(newImages);
            setIsLoading(false);
            setAllContent(data);
        }
    };

    const appendImages = (data) => {
        let newImages = [];
        if (windowSize.current[0] < 500) {
            newImages = [[], []];
        } else {
            newImages = [[], [], [], [], []];
        }
        if (data && data.length > 0) {
            data.forEach((image, index) => {
                newImages[index % newImages.length].push(image)
            });

            newImages.forEach((col, index) => {
                newImages[index] = images[index].concat(newImages[index]);
            });
            _setImages(newImages);
            setIsLoading(false);
            setAllContent([...allContent, additionalContent]);
        }
    };

    function likeContentPost(event, image) {
        event.stopPropagation();
        const token = localStorage.getItem("token");
        toggleLike(image.id, token)
            .then(resp => {
                if (resp.status == 200) {
                    const newImages = images.map(col => {
                        return col.map(img => {
                            if (img.id === image.id) {
                                return {...img, liked: !img.liked};
                            }
                            return img;
                        })
                    })
                    _setImages(newImages)
                    likeFunc(image.id);

                }
            })
            .catch(err => {
                if (err.response && err.response.status == 401) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    setLoginMessage("to like " + image.user + "'s photo");
                    openLoginModal();
                }
            })
    }

    function downloadFile(event, img) {
        event.stopPropagation();
        const token = localStorage.getItem("token");
        getDownloadFile(img.id, token)
            .then(resp => {
                if (resp.status == 200) {
                    const url = URL.createObjectURL(new Blob([resp.data]));
                    if (img.content_type === "VIDEO") {
                        setDownloadFileName(img.user.toLowerCase().replace(" ", "-") + "-dupe.mov")
                        setUrl(url);
                    } else {
                        setDownloadFileName(img.user.toLowerCase().replace(" ", "-") + "-dupe.jpeg")
                        setUrl(url);
                    }
                    event.stopPropagation();
                }
            })
            .catch(err => {
                if (err.response.status == 402) {
                    if (!token) {
                        setLoginMessage("to download more images");
                        openLoginModal();
                    }
                }
            })
    }

    function handleScroll() {
        const contentDiv = document.getElementById('content-container-div');
        if (contentDiv.getBoundingClientRect().bottom <= (window.innerHeight + 1000) && !isLoading) {
            setIsLoading(true);
            retrieveNextContent();
        }
        if ((window.innerHeight + contentDiv.getBoundingClientRect().y < 0) && !displayTopButtonRef.current) {
            setDisplyTopButton(true);
            displayTopButtonRef.current = true;
        } else if ((window.innerHeight + contentDiv.getBoundingClientRect().y > 0) && displayTopButtonRef.current) {
            setDisplyTopButton(false);
            displayTopButtonRef.current = false;
        }
    }

    function scrollToTop() {
        scrollRef.current.scrollIntoView();
    }

    function loadS3Content(contentId) {
        console.log("loading s3")
        getS3Content(contentId)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    console.log(resp);
                    const url = URL.createObjectURL(resp.data);
                    console.log(url);
                    return url;
                }
            })
    }

    useLayoutEffect(() => {
        const userAtr = JSON.parse(localStorage.getItem("user"));
        setUser(userAtr);
        setImages(content);

    }, [content]);

    useLayoutEffect(() => {
        appendImages(additionalContent);

    }, [additionalContent]);

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, [isLoading]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (collectionOptionRef.current && !collectionOptionRef.current.contains(event.target)) {
                setCollectionEditId(null);
            }
            if (collectionAddRef.current && !collectionAddRef.current.contains(event.target)) {
                closeCollectionModal();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };

    }, []);

    useEffect(() => {
        if (searchParams.get("content")) {
            openContentModal(searchParams.get("content"));
        } else if (contentModal) {
            closeContentModal();
        }

    }, [searchParams]);

    useEffect(() => {
        if (ref.current) {
            ref.current.click();
        }

    }, [url]);

    function handleIntersection(entries) {
        entries.forEach(entry => {
            const video = entry.target;

            if (entry.isIntersecting) {
                video.play();
            } else {
                video.pause();
            }
        });
    }


    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 1.0
        });

        const videos = document.querySelectorAll('.video');
        videos.forEach(video => {
            observer.observe(video);
        });
    }, []);

    return (
        <div className={"content-relative-div"}>
            <div className={"content-container-div"} id={"content-container-div"} ref={scrollRef}>
                {images && images.map((column, index) => (
                    <div className="image-column">
                        {column.map((image) => (
                            <div className={"content-container"}>
                                {portfolioFlag && (
                                    <img className="content-image" alt=""
                                         src={"https://d3p3fw3rutb1if.cloudfront.net/private-content/" + image.img_preview_id}
                                         onClick={() => openContentModal(image.id)}/>
                                )}
                                {!portfolioFlag && image.content_type === "VIDEO" && (
                                    // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                                    <ReactPlayer width={"100%"} height={"100%"} style={{borderRadius: "17.5px"}} className="content-video" url={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + image.img_preview_id} muted={true} playing={true} loop={true} />
                                    )}
                                {!portfolioFlag && image.content_type !== "VIDEO" && (
                                    <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                                )}
                                {collectionEditId == image.id && (
                                    <div className={"collection-options-div box-shadow"} ref={collectionOptionRef}>
                                        <div className={"option-div pointer"}
                                             onClick={event => openConfirmationModal(event, image)}>
                                            <img className={"collection-options-img"} src={trashcanIcon}/>
                                            <p>Remove</p>
                                        </div>
                                        <div className={"option-div pointer"}
                                             onClick={event => makeEntryCover(event, image)}>
                                            <img className={"collection-options-img"} src={collectionIcon}/>
                                            <p>Make cover</p>
                                        </div>
                                    </div>
                                )}
                                <div className={image.content_type === "VIDEO" ? "video-content-overlay-div" : "content-overlay-div"} onClick={() => openContentModal(image.id)}>
                                    {ownProfile && (
                                        <div>
                                            <img className={"plus-icon"} alt=""
                                                 onClick={(e) => showCollectionOptions(e, image)} src={ellipsis}/>
                                        </div>
                                    )}
                                    {!ownProfile && !portfolioFlag && (
                                        <img className="plus-icon" alt=""
                                             onClick={(e) => openCollectionsModal(e, image)}
                                             src="https://icon-library.com/images/white-plus-icon/white-plus-icon-3.jpg"/>
                                    )}
                                    <img className="like-icon" alt="" onClick={(e) => likeContentPost(e, image)}
                                         src={image.liked ? greenHeart : whiteHeart}/>
                                    {!profileFlag && !portfolioFlag &&
                                        <div>
                                            <img className="download-icon" alt=""
                                                 onClick={(e) => downloadFile(e, image)} src={download}/>
                                        </div>
                                    }
                                    {profileFlag &&
                                        <img className="edit-icon" alt="" onClick={(e) => editFunc(e, image)}
                                             src={pencil}/>
                                    }
                                    <div onClick={event => event.stopPropagation()}>
                                        <Link className={"user-div-preview"} to={'/profile/' + image.username}
                                              reloadDocument>
                                            {image.profile_image_exists && (
                                                <img className="content-profile-image" alt=""
                                                     src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + image.user_id}/>
                                            )}
                                            {!image.profile_image_exists && (
                                                <img className="content-profile-image" alt="" src={defaultProfile}/>
                                            )}
                                            <div>
                                                <p className={"content-profile-name font-bold"}>{image.user}</p>
                                                <p className={"content-profile-username"}>@{image.username}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                {addCollectionId === image.id && (
                                    <div className={index == 0 ? "first-collection-box" : "collection-box"}
                                         ref={collectionAddRef}>
                                        {!createCollectionFlag && (
                                            <fragment>
                                                <div className={"collection-search"}>
                                                    <input type="text" placeholder="Search.."
                                                           onChange={filterCollections}/>
                                                </div>
                                                {collections && (
                                                    <div className={"add-collection-div"}>
                                                        {collections.map((collection) => (
                                                            <div className={"collection-preview"}>
                                                                {collection.collection_entries &&
                                                                    <img
                                                                        src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + collection.collection_entries[0].img_preview_id}
                                                                        alt=""/>
                                                                }
                                                                {!collection.collection_entries &&
                                                                    <img
                                                                        src={collectionCover}
                                                                        alt=""/>
                                                                }
                                                                <h5 className={collection.content_exists ? "shorter-h5" : ""}>{collection.name}</h5>
                                                                {collection.content_exists && (
                                                                    <button className={"remove-from-collection-btn"}
                                                                            onClick={() => removeFromCollection(collection.id)}>Remove</button>
                                                                )}
                                                                {!collection.content_exists && (
                                                                    <button className={"add-to-collection-btn"}
                                                                            onClick={() => addToCollection(collection.id)}>Save</button>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                <div className={"create-collection-footer"}>
                                                    <div className={"collection-plus pointer"}
                                                         onClick={openCreateCollection}>
                                                        <img
                                                            src={"https://icon-library.com/images/white-plus-icon/white-plus-icon-3.jpg"}
                                                            alt=""/>
                                                    </div>
                                                    <h5>Create Collection</h5>
                                                </div>
                                            </fragment>
                                        )}
                                        {createCollectionFlag && (
                                            <fragment>
                                                <div className={"new-collection-header"}>
                                                    <img className={"image-preview"}
                                                         src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + collectionImage.img_preview_id}
                                                         alt=""/>
                                                    <h5 className={"font-bold"}>Create Collection</h5>
                                                </div>
                                                <div className={"collection-input"}>
                                                    <input id="firstName" value={collectionName} placeholder={"Name"}
                                                           onChange={(e) => setCollectionName(e.target.value)}
                                                           maxLength="30"/>
                                                    <textarea id="firstName" value={collectionDescription}
                                                              placeholder={"Description"} maxLength="250"
                                                              onChange={(e) => setCollectionDescription(e.target.value)}/>
                                                </div>
                                                <div className={"create-collection-footer"}>
                                                    {collections && collections.length > 0 && (
                                                        <img className={"back-arrow pointer"} onClick={backCollection}
                                                             src={backArrowIcon} alt=""/>
                                                    )}
                                                    <button className={"blue-btn create-button"}
                                                            onClick={createNewCollection}>Create
                                                    </button>
                                                </div>
                                            </fragment>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {displyTopButton && (
                <img src={topArrowIcon} className={"top-button pointer"} alt={"top-arrow-img"} onClick={scrollToTop}/>
            )}
            <Modal
                isOpen={contentModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeContentModal}
                className={"content-modal"}
                style={style}
            >
                <div className={"content-summary"}>
                    <div className={"selected-content"}>
                        {contentSummary.portfolio_flag && (
                            <img alt="not fount"
                                 src={"https://d3p3fw3rutb1if.cloudfront.net/private-content/" + contentSummary.img_preview_id}/>
                        )}
                        {!contentSummary.portfolio_flag && contentSummary.content_type !== "VIDEO" && (
                            <img alt="not fount"
                                 src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + contentSummary.img_preview_id}/>
                        )}
                        {!portfolioFlag && contentSummary.content_type === "VIDEO" && (
                            // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                            <video className="content-image" controls loop autoPlay muted>
                                <source src={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + contentSummary.img_preview_id}
                                        type={"video/mp4"}/>
                            </video>
                        )}
                    </div>
                    <div className={"content-info"}>
                        <div className={"user-div"} onClick={() => navigate('/profile/' + contentSummary.username)}>
                            {contentSummary.profile_image_exists && (
                                <img className={"summary-profile-img"}
                                     src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + contentSummary.user_id}/>
                            )}
                            {!contentSummary.profile_image_exists && (
                                <img className={"summary-profile-img"} src={defaultProfile}/>
                            )}
                            <div className={"d-inline"}>
                                <h5>{contentSummary.user}</h5>
                            </div>
                            <div className={"d-inline"}>
                                <div className={"stats-div"}>
                                    <img className={"likes-img"} src={heartImg}/>
                                    <p className={"d-inline"}>{contentSummary.likes} &nbsp;Likes</p>
                                </div>
                                <div className={"stats-div"}>
                                    <img className={"likes-img"} src={viewsImg}/>
                                    <p className={"d-inline"}>{contentSummary.views} &nbsp;Views</p>
                                </div>
                            </div>
                        </div>
                        {!contentSummary.portfolio_flag && contentSummary.aesthetics && contentSummary.aesthetics.length > 0 && (
                            <div className={"aesthetics"}>
                                <h5 className={"font-bold"}>Aesthetics</h5>
                                <div className={"content-aesthetics-div"}>
                                    {contentSummary.aesthetics && contentSummary.aesthetics.map((aesthetic) => (
                                        <a className={"aesthetic-button"}
                                           href={'/home?aesthetic=' + aesthetic.aesthetic}>{aesthetic.aesthetic}</a>
                                    ))}
                                </div>
                            </div>
                        )}
                        {!contentSummary.portfolio_flag && contentSummary.labels &&
                            <div className={"labels"}>
                                {contentSummary.labels.map((label) => (
                                    <a className={"btn"} href={'/results?search=' + label.label}>{label.label}</a>
                                ))}
                            </div>
                        }
                        {contentSummary.portfolio_flag && (
                            <div className={"portfolio-info-div"}>
                                <h3 className={"font-bold"}>{contentSummary.title}</h3>
                                <p>{contentSummary.description}</p>
                            </div>
                        )}
                        {!contentSummary.portfolio_flag && (
                            <button className={"download-btn blue-btn"}
                                    onClick={event => downloadFile(event, contentSummary)}>Download</button>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={loginModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeLoginModal}
                className={"login-modal"}
                style={style}
            >
                <Login successfulLoginCallback={onLogin} message={loginMessage}/>
            </Modal>
            <Modal
                isOpen={confirmationModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeConfirmationModal}
                className={"confirmation-modal"}
                style={defaultStyle}
            >
                <div className={"confirmation-div"}>
                    <h5 className={"font-bold"}>Are you sure?</h5>
                    <p>Once you remove a photo, you can't undo it.</p>
                    <button className={"blue-btn confirmation-cancel-btn"} onClick={closeConfirmationModal}>wait, jk
                    </button>
                    <button className={"red-btn confirmation-confirm-btn"} onClick={deleteCollectionEntryFunc}>Remove
                    </button>
                </div>
            </Modal>
            <a href={url} download={downloadFileName} className="hidden" ref={ref}/>
        </div>
    );
}

export default Content

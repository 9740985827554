import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native-web';

const CustomCheckbox = ({ value, onValueChange, color = '#396DE0', borderWidth = 1 }) => {
  return (
    <TouchableOpacity onPress={() => onValueChange(!value)}>
      <View style={[
        styles.checkbox,
        { borderColor: color, borderWidth: borderWidth, backgroundColor: 'white' },
        value && { backgroundColor: color }
      ]}>
        {value && (
          <svg viewBox="0 0 16 16" width="16" height="16">
          <path
            fill="none"
            stroke="white"
            strokeWidth="2"
            d="M3,8 L7,12 L13,4"
          />
        </svg>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
  },
});

export default CustomCheckbox;
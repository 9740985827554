import React, {useEffect, useState} from 'react'
import "../stylesheets/Upload.css";
import Upload from "./Upload";


const UploadPage = () => {

    return(
        <div className="upload-background">
            <div className="container upload-lightbox row">
                <Upload portfolioFlag={false} />
            </div>
        </div>
    );
}

export default UploadPage

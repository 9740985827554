import axios from "axios";

export async function getAesthetics() {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/aesthetics/popular";
    const result = await axios.get(url);
    return result;
}

export async function searchAesthetics(search) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/aesthetics?label="+search;
    const result = await axios.get(url);
    return result;
}

export async function createAestheticSubmission(request, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/aesthetics/submissions";
    let result;
    if (token) {
        result = await axios.post(url, request, { headers: {'X-CLIENT-ID': token}});
    } else {
        result = await axios.post(url, request);
    }
    return result;
}

export async function getTrendingAesthetics() {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/aesthetics/content/trending";
    const result = await axios.get(url);
    return result;
}

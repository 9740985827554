import React, {useState} from 'react'
import '../stylesheets/LicenseTerms.css';


const LicenseTerms = () => {
    const licenseString = 'license';
    const termsString = 'terms';
    const [selectedTab, setSelectedTab] = React.useState(licenseString);

    return(
        <div className={"license-container"}>
            <div className={"license-header-div"}>
                <h3 className={"font-bold pointer " + (selectedTab === licenseString ? "selected-license-tab" : "")} onClick={event => setSelectedTab(licenseString)}>Dupe License</h3>
                <h3 className={"font-bold pointer " + (selectedTab === termsString ? "selected-license-tab" : "")} onClick={event => setSelectedTab(termsString)}>Terms of Service</h3>
                {selectedTab === licenseString && (
                    <div className={"license-div"}>
                        <p>Dupe provides you with an enduring, non-exclusive, global license to utilize, reproduce, adapt, distribute, showcase, and employ images from Dupe at no cost, including for commercial applications, without requiring approval from or providing attribution to the photographer or Dupe. However, this license does not encompass the privilege to aggregate Dupe images to establish a comparable or competing service.</p>
                    </div>
                )}
                {selectedTab === termsString && (
                    <div className={"terms-div"}>
                        <h5>1. Acceptance of Terms</h5>
                        <p>Dupe LLC (“Dupe”) provides technology-enabled services, including the software offering branded as
                            “Dupe Photos” and the website at https://dupephotos.com/ as well as other related subdomains, software,
                            content, services and/or platforms, including all versions and upgrades thereto (collectively, the “Services”). Your
                            use of the Services in order to upload or download Content (as define below) is subject to and governed by the
                            terms and conditions in this Terms of Service (this “TOS”). Dupe may, at its discretion, update this TOS at any time,
                            and depending on where you reside, we may require your further consent to any updates to this TOS. You can
                            access and review the most current version of this TOS at the URL for this page or by clicking on the “Terms of
                            Service” link within the Services, or as otherwise made available by Dupe.</p>
                        <p>PLEASE REVIEW THIS TOS CAREFULLY. BY REGISTERING FOR AN ACCOUNT OR OTHERWISE ACCESSING OR
                            USING THE SERVICES, YOU AGREE TO BE BOUND BY THIS TOS, INCLUDING ANY UPDATES OR REVISIONS POSTED
                            HERE OR OTHERWISE COMMUNICATED TO YOU. IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF
                            THIS TOS, YOU MAY NOT ACCESS OR USE THE SERVICES.</p>
                        <p>THIS TOS REQUIRES FINAL AND BINDING ARBITRATION TO RESOLVE ANY DISPUTE OR CLAIM ARISING
                            OUT OF OR RELATING IN ANY WAY TO THIS TOS, OR YOUR ACCESS TO OR USE OF THE SERVICES, INCLUDING THE
                            VALIDITY, APPLICABILITY OR INTERPRETATION OF THIS TOS, AND YOU AGREE THAT ANY SUCH CLAIM WILL BE
                            RESOLVED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION,
                            ARBITRATION OR OTHER SIMILAR PROCESS. PLEASE REVIEW SECTION 14 CAREFULLY TO UNDERSTAND YOUR
                            RIGHTS AND OBLIGATIONS WITH RESPECT TO THE RESOLUTION OF ANY CLAIM.</p>
                        <p>If you are entering into this TOS, you represent and warrant that you: (i) are of legal age to form a binding
                            contract; (ii) have the right, authority, and capacity to agree to and abide by this TOS; and (iii) are not a person
                            barred from using the Services under the laws of any applicable jurisdiction. THE SERVICES ARE NOT INTENDED
                            FOR USERS UNDER THE AGE OF 18 OR THE LOWEST AGE PERMITTED BY APPLICABLE LAW, AND SUCH USERS ARE
                            EXPRESSLY PROHIBITED FROM SUBMITTING ANY PERSONAL DATA OR USING ANY ASPECT OF THE SERVICES, AND
                            BY TAKING SUCH ACTIONS YOU AGREE, REPRESENT, AND WARRANT THAT YOU ARE 18 YEARS OF AGE OR OLDER
                            OR NO YOUNGER THAN THE LOWEST AGE PERMITTED BY APPLICABLE LAW.</p>
                        <p>IF YOU ARE ENTERING INTO THIS TOS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
                            REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED AND LAWFULLY ABLE TO BIND SUCH ENTITY TO THIS TOS,
                            IN WHICH CASE THE TERM “YOU” SHALL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, YOU
                            MAY NOT ACCESS OR USE THE SERVICES.</p>
                        <p>As further set forth in Section 5(c) , you hereby agree that you will (i) not upload any images, photos, or
                            other content created by generative AI or similar technology and (ii) you will not download any images, photos,
                            or other content to use with any generative AI or similar technology.</p>
                        <h5>2. RIGHTS</h5>
                        <p>(a) Grant. Subject to and conditioned on your compliance with this TOS (including as set forth in
                            Section 2(d) ), Dupe hereby grants you a personal, non-exclusive, non-transferable, non-sublicensable, revocable
                            license solely to use the Services for your personal, non-commercial or internal business use. Your access to and
                            use of the Services must further comply in all material respects with all usage guidelines posted by Dupe.</p>
                        <p>(b) Mobile Apps. Dupe may make available mobile software applications for access to and use of
                            certain components of the Services (collectively, “Mobile Apps”). Your access to and use of Mobile Apps is subject
                            to and governed by this TOS. If any Mobile App is downloaded by you from the iTunes App Store, Windows Phone
                            Store, Amazon Appstore, Google Play or any similar service (an “App Store”), your use of such Mobile App is
                            further subject to your compliance in all material respects with the terms and conditions of the applicable usage
                            rules set forth by such App Store. This TOS is between you and Dupe only, and not with the proprietor of any App Store (a “Third Party Proprietor”), and no Third Party Proprietor is responsible for Mobile Apps and the contents
                            thereof; however, any Third Party Proprietor and its subsidiaries are third-party beneficiaries of this TOS with
                            respect to Mobile Apps.</p>
                        <p>(c) Trademarks. You may not use “Dupe,” “Dupe Photos,” or any of Dupe’s names, brands,
                            trademarks, service marks or logos that Dupe makes available on the Services (“Marks”). Dupe claims trademark
                            protection over all such Marks, and you will not use the Marks except as expressly authorized herein. You will not
                            remove or alter the Marks or any proprietary notices on the Services. The Marks may not be included in or as part
                            of any registered corporate name, any other logo, or service or product name. You may not create any derivative
                            works of the Marks or use the Marks in a manner that creates or reasonably implies an inaccurate sense of
                            endorsement, sponsorship, or association with Dupe. You will not otherwise use business names or logos in a
                            manner that can mislead, confuse, or deceive any third party. All use of the Marks and all goodwill arising out of
                            such use will inure to Dupe’s benefit.</p>
                        <p>(d) Responsibility for Content. All information, data (including information received or provided by
                            you through your use of the Services), text, documents, artworks, images, photos, audio files, and other materials
                            accessible through the Services (“Content”) are the sole responsibility of the party from whom such information,
                            data, text, documents, artworks, images, photos, audio files, or materials originated. You acknowledge and agree
                            that: (i) the Services may provide access to and/or rely on Content from third parties, and such third parties, and
                            not Dupe, are entirely responsible for such Content; (ii) you, and not Dupe, are entirely responsible for all Content,
                            including any Provided Content (as defined below), that you submit, upload, email, transmit, or otherwise make
                            available through the Services or to Dupe and you shall ensure the accuracy, completeness, and integrity of the
                            Content; and (iii) you are solely responsible for giving all required notices and obtaining all necessary consents,
                            including all necessary rights and consents relating to privacy and personal likeness, before submitting Content
                            through or to the Services or Dupe. You further hereby represent and warrant that (1) you have all necessary rights
                            and licenses to make available, submit, provide, and transfer all Provided Content for Dupe to exercise its rights
                            granted and fulfill its obligations set forth herein, including as necessary for Dupe to process, use, make available,
                            distribute, or otherwise exploit any Provided Content, and (2) that your submission of any Content to Dupe will not
                            violate this TOS, any Dupe usage guidelines or other policies, or any laws applicable to such Content, including
                            without limitation intellectual property laws and any privacy or personal likeness laws governing any personal or
                            sensitive information contained in any Content. For the avoidance of doubt, by submitting Content to Dupe, you
                            represent and warrant (a) that you have all necessary rights and consents for Dupe to use, reproduce, modify,
                            adapt, create derivative works from, publicly perform, publicly display, distribute, make and have made such
                            Content as set forth in Section 4(a) and 4 (b) and for Dupe to transfer and make available such Content to other users.</p>
                        <p>(e) Accounts. To access and use the Services, you will need to create an account with Dupe (an
                            “Account”). To create this Account, you will be asked to provide an email address and create a username and
                            password. Dupe may request further additional information from you, including personally identifiable
                            information, in order to authenticate your Account. You may not share your Account or password with anyone.
                            You are fully responsible for all activities that occur under your Account, even if such activities were not authorized
                            by you. You agree to notify Dupe immediately of any unauthorized use of your Account or password or any other
                            similar breach of security. If your Account remains inactive for three months or longer, Dupe reserves the right to
                            suspend or terminate your Account, with or without notice to you, and delete your information and Provided
                            Content all without liability.</p>
                        <h5>3. PRIVACY POLICY</h5>
                        <p>In addition to this TOS, the Dupe Privacy Policy, available at www.dupephotos.com/privacy-policy,
                            (“Privacy Policy”) applies to how Dupe may process information provided as part of the Services. You acknowledge
                            and agree that by accessing or using the Services, Dupe may receive certain information about you, including
                            personal and sensitive data, as set forth in the Privacy Policy, and Dupe may collect, use, disclose, store, share, and
                            process such personal data in accordance with such Privacy Policy, which may be amended from time-to-time.</p>
                        <h5>4. PROPRIETARY RIGHTS</h5>
                        <p>(a) License to Content You Upload. You hereby grant Dupe and its service providers and end users a
                            perpetual, worldwide, royalty-free, fully-paid-up, non-exclusive, sublicensable, freely transferable license to use,
                            exploit, reproduce, modify, adapt, create derivative works from, publicly perform, publicly display, distribute, make
                            and have made all Content (in any form and any medium, whether now known or later developed) that you
                            provide in connection with the Services (such Content, the “Provided Content”). You acknowledge and agree that
                            the technical processing and transmission of Content in association with the Services, may require: (i) transmission
                            over various networks and across borders; and (ii) modifications to conform, connect, and adapt to technical
                            requirements of networks or devices. You may revoke the foregoing license to any Provided Content at any time by
                            removing the Provided Content from the Services or by notifying Dupe of your intent to remove any Provided
                            Content; provided, however, that the foregoing license shall not be revocable with respect to any Provided
                            Content downloaded by another user prior to you removing the Provided Content from the Services.</p>
                        <p>(b) License to Content Uploaded by Others. Subject to Section 5 and your compliance therewith,
                            Dupe hereby grants you a perpetual, revocable, worldwide, royalty-free, fully-paid-up, non-exclusive,
                            sublicensable, transferable license to use, exploit, reproduce, modify, adapt, create derivative works from, publicly
                            perform, publicly display, distribute, make and have made all Content (in any form and any medium, whether now
                            known or later developed) available for download from the Services (such Content, the “Downloadable Content”).</p>
                        <p>(c) Ownership of the Services. The Services provided to you hereunder or available to you through
                            the Services are licensed, not sold or assigned, and Dupe retains and reserves all rights not expressly granted in
                            this TOS. You acknowledge and agree that, as between you and Dupe, Dupe and its licensors own all rights, title,
                            and interest (including all intellectual property rights) in the Services and all data, content, code, artwork, and
                            other materials within the Services and derived thereof (excluding the Provided Content and Downloadable
                            Content as set forth in Sections 4(a) and 4(b) ). The Services are protected by U.S. and international copyright and
                            other intellectual property laws and treaties. Dupe reserves all rights not expressly granted to you in this TOS.</p>
                        <h5>5. USER CONDUCT AND RESTRICTIONS</h5>
                        <p>(a) Prohibited Conduct. In your use of the Services, you will not:</p>
                        <p>(i) use, reproduce, modify, adapt, create derivative works from, sublicense, publicly
                            perform, publicly display, distribute, sell, lease, rent, make, have made, assign, pledge, transfer or otherwise grant
                            rights to the Services, except as expressly permitted under this TOS;</p>
                        <p>(ii) reverse engineer, disassemble, decompile, translate, or otherwise attempt to derive
                            trade secrets, algorithms, or the source code, architectural framework, or data records, within or associated with
                            the Services;</p>
                        <p>(iii) interfere with or disrupt the integrity or performance of the Services, including by
                            disrupting the ability of any other person to use or enjoy the Services;</p>
                        <p>(iv) provide use of the Services on a service bureau, rental, or managed services basis,
                            provide, or permit other individuals or entities to create Internet “links” to the Services or “frame” or “mirror” the
                            Services on any other server, or wireless or Internet-based device;</p>
                        <p>(v) access the Services for the purpose of developing, marketing, selling, or distributing any
                            product or service that competes with or includes features substantially similar to the Services;</p>
                        <p>(vi) violate any applicable local, state, provincial, federal, or international law or regulation,
                            or use the Services for any illegal, unauthorized, or otherwise improper purposes, including to store or transmit
                            malicious code, or to store or transmit material in violation of third-party privacy rights;</p>
                        <p>(vii) remove or obscure any proprietary notice that appears within the Services;</p>
                        <p>(viii) impersonate any person or entity, including Dupe personnel, or falsely state or
                            otherwise misrepresent your affiliation with Dupe, or any other entity or person;</p>
                        <p>(ix) forge headers or otherwise manipulate identifiers in order to disguise the origin of any
                            content transmitted through the Services;</p>
                        <p>(x) take any action that imposes an unreasonable or disproportionately heavy load on the
                            Services or its infrastructure; or</p>
                        <p>(xi) use spiders, crawlers, robots, scrapers, automated tools, or any other similar means to
                            access the Services; or download, reproduce, or archive any substantial portion of the Services.</p>
                        <p>(b) Prohibited Content. You will not upload, submit, post, email, store, transmit, or otherwise make
                            available any Provided Content or other Content that:</p>
                        <p>(i) is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar,
                            pornographic, hateful, discriminatory, libelous, invasive of another’s privacy, hateful, or otherwise objectionable;</p>
                        <p>(ii) contains nudity, politically or racially motivated material, explicitly violent or sexual
                            material, or depictions of violent, hateful, discriminatory or sexual acts;</p>
                        <p>(iii) may not be made available under any law or under contractual or fiduciary relationships
                            (such as confidential or proprietary information learned as part of an employment relationship or under a non-
                            disclosure agreement);</p>
                        <p>(iv) infringes, misappropriates, or otherwise violates any patent, trademark, trade secret,
                            copyright, or other proprietary right of any person;</p>
                        <p>(v) violates any person’s privacy, likeness, publicity, personality, or similar rights;</p>
                        <p>(vi) consists of unsolicited or unauthorized advertising, promotional materials, junk mail,
                            spam, chain letters, pyramid schemes, commercial electronic messages, or any other form of solicitation;</p>
                        <p>(vii) contains software viruses or any other code, files or programs designed to interrupt,
                            destroy, or limit the functionality of any software or hardware;</p>
                        <p>(viii) contains infringing, libelous, or otherwise unlawful or tortious material; or</p>
                        <p>(ix) consists of information that you know or have reason to know is false or inaccurate.</p>
                        <p>(c) Generative AI.</p>
                        <p>(i) Provided Content. You will not upload, input, provide, or otherwise make available any
                            Content to the Services that was created, generated, altered, or otherwise derived from any generative artificial
                            intelligence, large-language model, or similar technology (“Generative AI”) without the prior, express, written
                            consent of Dupe.</p>
                        <p>(ii) Downloadable Content. You will not use, input, provide, or otherwise make available
                            any Downloadable Content for use by or in connection with any Generative AI, including to train or otherwise
                            improve the technology, without the prior, express, written consent of Dupe.</p>
                        <p>(d) Commercial Exploitation. You may not sell any Downloadable Content as downloaded or
                            distributed from the Services. Rather, any Downloadable Content may be sold only to the extent you materially
                            modify, alter, or redesign the Downloadable Content.</p>
                        <p>(e) Enforcement. Dupe shall have sole discretion and control over (i) moderating and removing any
                            Content, including Provided Content, and (ii) determining whether any Content violates this TOS or any guidelines
                            set forth by Dupe or otherwise. Dupe’s failure to enforce any of these restrictions or guidelines shall not act as a
                            waiver for any future enforcement, will not be considered a breach of this TOS by Dupe, and does not create a
                            private right of action for any other party.</p>
                        <h5>6. FEEDBACK</h5>
                        <p>If you elect to provide or make available to Dupe any suggestions, comments, ideas, improvements or
                            other feedback relating to the Services as provided through the Services or otherwise (“Feedback”), Dupe shall
                            own and be free to use, reproduce, modify, adapt, create derivative works from, publicly perform, publicly display,
                            distribute, make, have made, assign, pledge, transfer or otherwise grant rights in your Feedback in any form and
                            any medium (whether now known or later developed), without credit or compensation to you.</p>
                        <h5>7. DEALINGS WITH ADVERTISERS AND OTHER THIRD PARTIES</h5>
                        <p>Third-Party Services. THE SERVICES MAY INCLUDE OR PROVIDE ACCESS TO THIRD PARTY PRODUCTS,
                            SERVICES, CONTENT, DATA, OR OFFERINGS (“THIRD-PARTY SERVICES”). YOU ACKNOWLEDGE THAT DIFFERENT
                            TERMS OF USE AND PRIVACY POLICIES MAY APPLY TO YOUR USE OF SUCH THIRD-PARTY SERVICES AND THAT
                            SUCH TERMS AND POLICIES ARE SOLELY BETWEEN YOU AND THE ADVERTISER OR OTHER THIRD PARTY. YOU
                            AGREE THAT DUPE DOES NOT ENDORSE AND IS NOT RESPONSIBLE OR LIABLE FOR ANY ISSUES RELATED TO
                            THIRD-PARTY SERVICES.</p>
                        <h5>8. INDEMNIFICATION</h5>
                        <p>You shall indemnify and hold Dupe and its affiliates, and each of their officers, directors, members,
                            managers, employees, agents, partners and licensors (collectively, “Dupe Parties”) harmless from and against all
                            losses, damages, costs, liabilities, and expenses, including reasonable attorneys’ fees, to extent resulting from or
                            arising out of any third party claim, demand, or action due to (i) Content or other content, including Provided
                            Content, you provide to Dupe; (ii) your violation of this TOS, any law or regulation, or any rights (including
                            intellectual property or privacy rights) of another party; or (iii) your use of the Services, except as expressly
                            permitted in this TOS.</p>
                        <h5>9. DISCLAIMER OF WARRANTIES</h5>
                        <p>(a) General Disclaimer. YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
                            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. TO THE MAXIMUM EXTENT PERMITTED
                            BY APPLICABLE LAW, DUPE PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                            IMPLIED OR ARISING FROM STATUTE, COURSE OF DEALING, USAGE OF TRADE OR OTHERWISE, INCLUDING THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-
                            INFRINGEMENT.</p>
                        <p>(b) No Implied Representations and Warranties. DUPE PARTIES MAKE NO WARRANTY OR
                            REPRESENTATION THAT: (i) THE SERVICES WILL MEET YOUR REQUIREMENTS; (ii) ACCESS TO THE SERVICES WILL BE
                            UNINTERRUPTED, TIMELY, SECURE, VIRUS-FREE, OR ERROR-FREE; OR (iii) THE CONTENT (INCLUDING ANY
                            DOWNLOADABLE CONTENT) THAT MAY BE OBTAINED FROM ACCESS TO OR USE OF THE SERVICES WILL BE
                            ACCURATE, RELIABLE, CURRENT, OR COMPLETE.</p>
                        <p>(c) Non-Reliance. ALL CONTENT (INCLUDING ALL DOWNLOADABLE CONTENT) MADE AVAILABLE
                            THROUGH THE SERVICES IS MADE AVAILABLE FOR INFORMATIONAL PURPOSES ONLY. YOU ARE SOLELY
                            RESPONSIBLE FOR CONFIRMING THE ACCURACY OF ALL CONTENT BEFORE TAKING OR OMITTING ANY ACTION.
                            YOU SHOULD NOT RELY ON THE SERVICES AND YOU SHOULD NOT USE THE SERVICES FOR ADVICE OF ANY KIND.</p>
                        <p>(d) Disclaimer Limitation. THIS DISCLAIMER OF WARRANTY MAY NOT BE VALID IN SOME
                            JURISDICTIONS AND YOU MAY HAVE WARRANTY RIGHTS UNDER LAW WHICH MAY NOT BE WAIVED OR
                            DISCLAIMED. ANY SUCH WARRANTY EXTENDS ONLY FOR THIRTY (30) DAYS FROM THE EFFECTIVE DATE OF THIS
                            TOS (UNLESS SUCH LAW PROVIDES OTHERWISE).</p>
                        <h5>10. LIMITATION OF LIABILITY</h5>
                        <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, DUPE PARTIES SHALL NOT BE LIABLE FOR ANY LOST
                            PROFITS, LOSS OF DATA OR GOODWILL, OR COST OF COVER, OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                            EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING DAMAGES ARISING FROM ANY TYPE OR
                            MANNER OF COMMERCIAL, BUSINESS, OR FINANCIAL LOSS, EVEN IF DUPE PARTIES HAD ACTUAL OR
                            CONSTRUCTIVE KNOWLEDGE OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH
                            DAMAGES WERE FORESEEABLE. EXCEPT AS SET FORTH IN SECTION 11(b) , IN NO EVENT SHALL THE DUPE PARTIES’
                            TOTAL LIABILITY TO YOU EXCEED ONE-THOUSAND DOLLARS ($1,000). THE FOREGOING LIMITATIONS, EXCLUSIONS,
                            AND DISCLAIMERS SHALL APPLY REGARDLESS OF WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON
                            CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR
                            NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. INSOFAR AS APPLICABLE
                            LAW PROHIBITS ANY LIMITATION ON LIABILITY HEREIN, THE PARTIES AGREE THAT SUCH LIMITATION WILL BE
                            AUTOMATICALLY MODIFIED, BUT ONLY TO THE EXTENT SO AS TO MAKE THE LIMITATION COMPLIANT WITH
                            APPLICABLE LAW. THE PARTIES AGREE THAT THE LIMITATIONS ON LIABILITIES SET FORTH HEREIN ARE AGREED
                            ALLOCATIONS OF RISK AND SUCH LIMITATIONS WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL
                            PURPOSE OF ANY LIMITED REMEDY.</p>
                        <h5>11. BETA SERVICES</h5>
                        <p>(a) Beta Services Disclaimer. AS FURTHER SET FORTH IN SECTION 9 , ALL DUPE SERVICES LABELED
                            ALPHA, BETA, PRE-RELEASE, TRIAL, PREVIEW OR SIMILARLY (“Beta Services”) ARE PROVIDED “AS IS”, “AS
                            AVAILABLE”, WITH ALL FAULTS, AND CUSTOMER’S USE OF SUCH BETA SERVICES IS AT ITS SOLE RISK AND DUPE
                            DISCLAIMS ANY WARRANTY OR LIABILITY OBLIGATIONS OF ANY KIND. Dupe has no obligations in connection with
                            or in the course of providing the Beta Services. Any expectations and estimates regarding Beta Services are based
                            on factors currently known and actual events or results could differ materially. Dupe does not assume any
                            obligation to update any Beta Services. In addition, any information about Dupe’s roadmap outlines Dupe’s general
                            product direction and is subject to change at any time without notice. It is for informational purposes only and
                            shall not be incorporated into this TOS or any contract or other commitment. Dupe undertakes no obligation either
                            to develop the features or functionality provided in the Beta Services, or to include any such feature or
                            functionality in a future release of the Services. You expressly acknowledge that the Beta Services have not been
                            fully tested and may contain defects or deficiencies which may not be corrected by Dupe. The Beta Services may
                            undergo significant changes prior to release of the corresponding generally available final version.</p>
                        <p>(b) Beta Services Liability Waiver. NOTWITHSTANDING SECTION 9(d) , WHERE LEGAL LIABILITY
                            CANNOT BE EXCLUDED, BUT MAY BE LIMITED, DUPE’S LIABILITY AND THAT OF ITS SUPPLIERS AND AUTHORIZED
                            PARTNERS SHALL BE LIMITED TO THE SUM OF ONE HUNDRED DOLLARS ($100) FOR ANY AND ALL CLAIMS ARISING
                            FROM OR RELATING TO THE BETA SERVICES.</p>
                        <h5>12. SUSPENSION AND TERMINATION</h5>
                        <p>(a) Termination for Convenience. Either party may terminate this TOS at any time. To terminate this
                            agreement, you may navigate to your Account and follow the instructions provided therein or reach out to Dupe at
                            support@dupephotos.com.</p>
                        <p>(b) Termination or Suspension for Cause. If you violate this TOS, Dupe may, with or without notice
                            to you, immediately suspend, or terminate your access and use of the Services.</p>
                        <p>(c) Right to Modify Services. Dupe reserves the right at any time to modify, suspend, or discontinue
                            the Services (or any portion thereof) with or without notice, and Dupe shall not be liable to you or any third party
                            for any such modification or discontinuance;</p>
                        <p>(d) Effect of Termination; Survival. Upon termination of this TOS for any reason: (i) Dupe, in its sole
                            discretion, may remove and discard your Provided Content and other information; (ii) you will immediately cease
                            your use of the Services; and (iii) any provision that, by its terms, is intended to survive the expiration or
                            termination of this TOS shall survive such expiration or termination. Further, you agree that Dupe shall not be
                            liable to you or any third party for any termination of your Account or access to the Services.</p>
                        <h5>13. GOVERNING LAW</h5>
                        <p>This TOS shall be governed by and construed and enforced in accordance with the United States Federal
                            Arbitration Act, other applicable federal laws, and the laws of the State of Tennessee, without regard to conflict of
                            laws principles. The parties agree that the United Nations Convention on Contracts for the International Sale of
                            Goods shall not apply to this TOS, regardless of the states in which the parties do business or are incorporated.</p>
                        <h5>14. BINDING ARBITRATION AND CLASS ACTION WAIVER</h5>
                        <p>(a) Binding Arbitration. ALL CLAIMS ARISING IN CONNECTION WITH THIS TOS SHALL BE RESOLVED
                            BY FINAL AND BINDING ARBITRATION RATHER THAN IN COURT, EXCEPT THAT YOU MAY ASSERT CLAIMS IN SMALL
                            CLAIMS COURT (DEFINED FOR THE PURPOSES OF THIS TOS AS A COURT OF LIMITED JURISDICTION THAT MAY ONLY
                            HEAR CLAIMS NOT EXCEEDING $10,000) IF YOUR CLAIMS ARE WITHIN THE COURT’S JURISDICTION. THERE IS NO
                            JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.</p>
                        <p>(b) Arbitration Procedure. The arbitration shall be conducted by the American Arbitration
                            Association (AAA) under its then-applicable Commercial Arbitration Rules or, as appropriate, its Consumer
                            Arbitration Rules. The AAA’s rules are available at http://www.adr.org/. Payment of all filing, administration and
                            arbitrator fees shall be governed by the AAA’s rules. The arbitration shall be conducted in the English language by a
                            single independent and neutral arbitrator. For any hearing conducted in person as part of the arbitration, you
                            agree that such hearing shall be conducted in Hamilton County, Tennessee, or if the Consumer Arbitration Rules
                            apply, another location reasonably convenient to both parties with due consideration of their ability to travel and
                            other pertinent circumstances, as determined by the arbitrator. The decision of the arbitrator shall be final and
                            binding. Judgment on the arbitral award may be entered in any court of competent jurisdiction.</p>
                        <p>(c) Class Action Waiver. WE EACH AGREE THAT ALL CLAIMS SHALL BE RESOLVED ONLY ON AN
                            INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION OR OTHER SIMILAR
                            PROCESS (INCLUDING ARBITRATION). IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN
                            ARBITRATION, WE EACH WAIVE ANY RIGHT TO A JURY TRIAL AND AGREE THAT SUCH CLAIM SHALL BE BROUGHT
                            ONLY IN A COURT OF COMPETENT JURISDICTION IN HAMILTON COUNTY, TENNESSEE. YOU HEREBY SUBMIT TO
                            THE PERSONAL JURISDICTION AND VENUE OF SUCH COURTS AND WAIVE ANY OBJECTION ON THE GROUNDS OF
                            VENUE, FORUM NON-CONVENIENS OR ANY SIMILAR GROUNDS WITH RESPECT TO ANY SUCH CLAIM.</p>
                        <p>(d) Injunctive Relief. Notwithstanding anything to the contrary, either party may seek injunctive
                            relief and any other equitable remedies from any court of competent jurisdiction, whether in aid of, pending, or
                            independently of the resolution of any dispute pursuant to the arbitration procedures set forth in this Section 14 .</p>
                        <p>(e) Effect of Changes. If Dupe implements any material change to this Section 14 , such change shall
                            not apply to any Claim for which you provided written notice to Dupe before the implementation of the change.</p>
                        <h5>15. LEGAL COMPLIANCE</h5>
                        <p>You represent and warrant that you will comply with all applicable foreign, federal, state, and local laws,
                            rules and regulations, including without limitation, U.S. export laws and import and use laws of the country where
                            Licensed Material is delivered or used and you are not: (i) located in a country that is subject to a U.S. Government
                            embargo, or designated by the U.S. Government as a “terrorist supporting” country; and (ii) listed on any U.S.
                            Government list of prohibited or restricted parties, including the Specially Designated Nationals List.</p>
                        <h5>16. U.S. GOVERNMENT ENTITIES</h5>
                        <p>This section applies to access to or use of the Services by a branch or agency of the United States
                            Government. The Services includes “commercial computer software” and “commercial computer software
                            documentation” as such terms are used in 48 C.F.R. § 12.212 and qualifies as “commercial items” as defined in 48
                            C.F.R. § 2.101. Such items are provided to the United States Government: (i) for acquisition by or on behalf of
                            civilian agencies, consistent with the policy set forth in 48 C.F.R. § 12.212; or (ii) for acquisition by or on behalf of
                            units of the Department of Defense, consistent with the policies set forth in 48 C.F.R. §§ 227.7202-1 and 227.7202-
                            3. The United States Government shall acquire only those rights set forth in this TOS with respect to the such
                            items, and any access to or use of the Services by the United States Government constitutes: (a) agreement by the
                            United States Government that such items are “commercial computer software” and “commercial computer
                            software documentation” as defined in this section; and (b) acceptance of the rights and obligations herein.</p>
                        <h5>17. PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT OR TRADEMARK INFRINGEMENT</h5>
                        <p>If you believe that your work has been made available through the Services in a way that constitutes
                            copyright infringement or you are the owner of any trademark, trade name, logo or service mark, or are an agent
                            of such owner, and believe that any Content available on the Services constitutes trademark infringement, please
                            provide Dupe’s Agent for Notice of Copyright Claims the following information: (i) a physical or electronic signature
                            of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (ii) a
                            description of the work or mark claimed to have been infringed, or, if multiple copyrighted works or protected
                            marks are covered by a single notification, a representative list of such works or marks; (iii) a description of the
                            material that you claim is infringing and where that material may be accessed within the Services; (iv) your
                            address, telephone number and email address; (v) a statement by you that you have a good-faith belief that use of
                            the material in the manner complained of is not authorized by the copyright or trademark owner, its agent or the
                            law; and (vi) a statement from you that the information in the notification is accurate and, under penalty of
                            perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. If you
                            believe in good faith that a notice of copyright infringement has been wrongly filed against you, please contact
                            Dupe’s Agent for Notice of Copyright Claims. Dupe’s Agent for Notice of Copyright Claims can be reached as
                            follows:</p>
                        <p>Agent for Notice of Copyright Claims<br />
                            310 W 17th St.<br />
                            Chattanooga, TN 37408<br />
                            Phone Number: (423) 605-8135<br />
                            Email: copyright@dupephotos.com</p>
                        <h5>18. CALIFORNIA USERS &amp; RESIDENTS</h5>
                        <p>In accordance with California Civil Code § 1789.3, you may report complaints to the Complaint Assistance
                            Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting such unit
                            in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.</p>
                        <h5>19. GENERAL PROVISIONS</h5>
                        <p>This TOS constitutes the entire agreement between you and Dupe concerning your access to and use of
                            the Services. It supersedes all prior and contemporaneous oral or written negotiations and agreements between
                            you and Dupe with respect to such subject matter. In the event of any conflict between or among this TOS and any
                            other agreement, privacy policy, or usage guidelines to which this TOS refers, the terms and conditions of this TOS
                            shall take precedence and govern. This TOS may not be amended by you except in a writing executed by you and
                            an authorized representative of Dupe. You hereby consent and authorize Dupe to contact and communicate with
                            you through electronic means, including through email. Except as otherwise expressly provided in this TOS, there
                            shall be no third-party beneficiaries to this TOS. For the purposes of this TOS, the words “such as,” “include,”
                            “includes” and “including” shall be deemed to be followed by the words “without limitation.” You may not assign
                            or delegate any right or obligation under this TOS without the prior written consent of Dupe. The failure of Dupe to
                            exercise or enforce any right or provision of this TOS shall not constitute a waiver of such right or provision. If any
                            provision of this TOS is held to be invalid or unenforceable under applicable law, then such provision shall be
                            construed, limited, modified or, if necessary, severed to the extent necessary to eliminate its invalidity or
                            unenforceability, without in any way affecting the remaining parts of this TOS. Any prevention of or delay in
                            performance by Dupe hereunder due to labor disputes, acts of god, failure of the Internet, governmental
                            restrictions, enemy or hostile governmental action, fire or other casualty or other causes beyond its reasonable
                            control shall excuse the performance of its obligations for a period equal to the duration of any such prevention or
                            delay.</p>
                    </div>
                )}
            </div>
        </div>

    );
}

export default LicenseTerms

import React, {useState} from 'react'
import '../stylesheets/LicenseTerms.css';


const PrivacyPolicy = () => {
    const licenseString = 'license';
    const termsString = 'terms';
    const [selectedTab, setSelectedTab] = React.useState(licenseString);

    return(
        <div className={"license-container"}>
            <object data="https://dupe-photos.s3.amazonaws.com/Dupe+-+Privacy+Policy+(2024-04-10+KG).docx.pdf" type="application/pdf" width="100%" height="100%" />
            {/*<div className={"terms-div"}>*/}
            {/*    <h3 className={"font-bold"}>Privacy Policy</h3>*/}
            {/*    <p>Your privacy is important to Dupe LLC (“Dupe”, “we”, “us”, or “our”). This Dupe Privacy Policy*/}
            {/*        (“Privacy Policy”) describes how Dupe may collect, use, store, disclose, process, and transfer*/}
            {/*        your personal information, through your access to or use of products and services, including*/}
            {/*        those at www.dupephotos.com, subdomains, any website of ours that links to this Privacy*/}
            {/*        Policy, related websites, mobile software applications, and other offerings (collectively, the*/}
            {/*        “Services”). By using these Services or opting into this Privacy Policy, you signify your*/}
            {/*        acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use*/}
            {/*        the Services. Dupe may change this Privacy Policy from time to time by posting changes at*/}
            {/*        www.dupephotos.com/privacy-policy and may send notification emails regarding such changes.*/}
            {/*        Your continued use of the Services following the posting of such changes will be deemed your*/}
            {/*        acceptance of those changes.</p>*/}
            {/*    <p>Dupe encourages you to read this Privacy Policy in its entirety, but here are a few key points of*/}
            {/*        our privacy practices:</p>*/}
            {/*    <h5>Privacy Policy Applicability</h5>*/}
            {/*    <p>This Privacy Policy applies when you access the Services or share information with Dupe. Dupe*/}
            {/*        may revise this Privacy Policy at any time.</p>*/}
            {/*    <h5>Information We Collect from You</h5>*/}
            {/*    <p>We collect all information that you provide including information you provide via the Services,*/}
            {/*        your devices (e.g., computers, smartphones, and tablets), telephone and email, as well as*/}
            {/*        information we receive from partners and using cookies and other such technologies.</p>*/}
            {/*    <h5>How We Use Your Information</h5>*/}
            {/*    <p>We process your personal information only with your consent or as needed to provide you the*/}
            {/*        Services. We may also use your personal information to comply with legal obligations, operate*/}
            {/*        our business, protect the vital interests of you, our customers, or the public, or for other*/}
            {/*        legitimate interests of Dupe.</p>*/}
            {/*    <h5>How We Share Your Information</h5>*/}
            {/*    <p>We share your information with our partners, service providers, contractors, agents, and third-*/}
            {/*        party vendors as they need it to help us provide the Services or your subscription to the*/}
            {/*        Services, or for other requests you make of Dupe. We may also share your information to*/}
            {/*        comply with law or for other legitimate interests.</p>*/}
            {/*    <h5>How We Store and Secure Your Information</h5>*/}
            {/*    <p>Dupe may store your information in any country where Dupe or its affiliates, partners, or*/}
            {/*        providers operate facilities, and we will retain your information as long as necessary for the*/}
            {/*        purposes outlined in this Privacy Policy or for so long as permitted by law, whichever is shorter.*/}
            {/*        Dupe takes technological and organizational measures designed to protect your personal*/}
            {/*        information against loss, theft, and unauthorized access, use, disclosure, or modification.</p>*/}
            {/*    <h5>Accessing and Updating Your Information</h5>*/}
            {/*    <p>You can access, get copies of, delete, change, or correct your personal information, or request*/}
            {/*        changes to our use of your personal information by, using the contact information below. You*/}
            {/*        may have additional rights based on where you reside.</p>*/}
            {/*    <h5>Additional Privacy Information</h5>*/}
            {/*    <p>Dupe’s databases are located in the United States, and you consent to the transfer of your*/}
            {/*        personal information from your location to the United States. The Services are not for use by*/}
            {/*        children under the age of 16 years old or the lowest age permitted by applicable law. You may*/}
            {/*        have additional rights under applicable law. If you have any questions about this privacy policy,*/}
            {/*        please contact us via the information below.</p>*/}
            {/*    <h5>European Union, United Kingdom, and Swiss Data Subject Rights</h5>*/}
            {/*    <p>If you are an EU, UK, or Swiss resident, you may have additional rights under applicable privacy*/}
            {/*        laws and regulations.</p>*/}
            {/*    <h5>Privacy Policy Applicability</h5>*/}
            {/*    <p>This Privacy Policy applies when you access or use the Services or share information with*/}
            {/*        Dupe. By accessing or using the Services and/or opting into this Privacy Policy (as applicable in*/}
            {/*        your jurisdiction), you consent to this Privacy Policy. Dupe may update this Privacy Policy at any*/}
            {/*        time. If you do not consent to this Privacy Policy for any reason, please do not access or use the*/}
            {/*        Services or share your information with us. Please note that this Privacy Policy applies only to*/}
            {/*        the Services and not to any other third-party website linked to or from it, or any third-party*/}
            {/*        website in which Dupe’s content or functionality is embedded. We do not control the privacy*/}
            {/*        policies or practices of others.</p>*/}
            {/*    <h5>Information We Collect from You</h5>*/}
            {/*    <p>In general, we receive and collect all information you provide via the Services, including through*/}
            {/*        website input fields (including advertisements), phone, email (including email campaigns), web*/}
            {/*        chat, or other such ways.</p>*/}
            {/*    <p>You have the choice on what information to share and the Services you want to engage. You*/}
            {/*        can choose not to provide information to us, but in general, some information about you is*/}
            {/*        required in order for you to access certain functionalities of the Services.x*/}
            {/*        We also may periodically obtain information about you from affiliated entities, partners and other*/}
            {/*        independent third-party sources and will add it to our database of information, including any*/}
            {/*        information that you provide to third parties that are associated or whose products and/or*/}
            {/*        services are integrated with the Services.*/}
            {/*        We may use cookies, heat mapping, log files, web beacons, device identifiers, advertising*/}
            {/*        identifiers, session replay technologies, and similar tracking technologies, including those from*/}
            {/*        third-party service providers like Amazon Web Services, Google Analytics, X (f/k/a Twitter), and*/}
            {/*        other cloud-based tools, to automatically collect your preferences, performance data, and*/}
            {/*        information about your web usage when you visit the Services. For example, we may collect*/}
            {/*        your IP address, device and Internet service provider information, Web browser details and the*/}
            {/*        address of any referring website. We may also collect information about your online activity,*/}
            {/*        such as pages viewed and interactions with other users. This may include collecting geolocation*/}
            {/*        signals from your IP address or device settings to determine your location. By collecting and*/}
            {/*        using this information, we may operate and personalize the Services for you, including to*/}
            {/*        provide more relevant ads and search results. For more information on how we use cookies,*/}
            {/*        please see the “Cookies” section below.*/}
            {/*        The Services are not designed to recognize or respond to “do not track” signals received from*/}
            {/*        browsers. You can control the information collected by such tracking technologies or be alerted*/}
            {/*        when cookies are sent by adjusting the settings on your Internet browser or devices, but such*/}
            {/*        adjustments may affect or disable certain functionality of the Services. You can learn more*/}
            {/*        about Google Analytics and your options for opting out of interest-based Google ads at*/}
            {/*        https://adssettings.google.com. You can learn more about targeted ads and your ability to opt*/}
            {/*        out of receiving interest-based ads at optout.aboutads.info and*/}
            {/*        www.networkadvertising.org/choices.</p>*/}
            {/*    <p>Cookies</p>*/}
            {/*    <p>Cookies, also known as tracking cookies or browser cookies, and similar technologies such as*/}
            {/*        web beacons, clear GIFs, pixel tags, and JavaScript (collectively, “Cookies”) are small pieces of*/}
            {/*        data, usually text files, placed on a computer, tablet, phone, or similar device when you use that*/}
            {/*        device to access the Services. We use the following types of Cookies:</p>*/}
            {/*    <ul>*/}
            {/*        <li>&#8226; Essential Cookies. Essential Cookies are necessary for providing you with services and*/}
            {/*            features that you requested. For example, these Cookies allow you to log into and stay*/}
            {/*            logged into secure areas of the Services, save language preferences, and more. These*/}
            {/*            Cookies are required to make the Services available to you, so they cannot be disabled.</li>*/}
            {/*        <li>&#8226; Performance/Analytical Cookies. Performance/Analytical Cookies allow us to*/}
            {/*            understand how visitors (like you) use our Services. These Cookies accomplish this by*/}
            {/*            collecting information about the number of visitors to the Services, what pages visitors*/}
            {/*            view the most, and how long visitors view specific pages. These Cookies also help us*/}
            {/*            measure and manage the performance of our advertising campaigns in order to help us*/}
            {/*            improve the campaigns and the Services’ content. For example, Google LLC (“Google”)*/}
            {/*            uses cookies in connection with its Google Analytics services. Google’s ability to use*/}
            {/*            and share information collected by Google Analytics about your visits to the Services is*/}
            {/*            subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have*/}
            {/*            the option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-*/}
            {/*            out page at http://www.google.com/privacy_ads.html or the Google Analytics Opt-out*/}
            {/*            Browser Add-on at https://tools.google.com/dlpage/gaoptout/.</li>*/}
            {/*    </ul>*/}
            {/*    <p>You have the option to decide through your internet browser’s settings whether or not to accept*/}
            {/*        Cookies. Most browsers allow users to choose whether to turn off the Cookie feature, which will*/}
            {/*        prevent your browser from accepting new Cookies, as well as (depending on the browser) allow*/}
            {/*        you to toggle whether to accept each new Cookie. You can also clear all Cookies that are*/}
            {/*        already on your device. If you do this, however, you may have to manually adjust some*/}
            {/*        preferences every time you visit the Services and some functionalities may not work.</p>*/}
            {/*    <p>To explore what Cookie settings are available to you, look in the “settings”, “preferences” or*/}
            {/*        “options” section of your browser’s menu. To find out more information about Cookies, including*/}
            {/*        information about how to manage and delete Cookies generally, please*/}
            {/*        visit http://www.allaboutcookies.org/.</p>*/}
            {/*    <h5>How We Use Your Information</h5>*/}
            {/*    <p>We process your personal information with your consent or as needed to provide you the*/}
            {/*        Services. We may also use your personal information to comply with legal obligations, operate*/}
            {/*        our business, protect the vital interests of you, our customers, or the public, or for other*/}
            {/*        legitimate interests of Dupe as described in this Privacy Policy.</p>*/}
            {/*    <p>More specifically, we may use your personal information to:</p>*/}
            {/*    <ul>*/}
            {/*        <li>&#8226; Optimize and improve the Services. We continually try to improve the Services based*/}
            {/*            on the information and feedback we receive from you and our other users, including by*/}
            {/*            optimizing the content on or functionalities of the Services.</li>*/}
            {/*        <li>&#8226; Personalize the user experience. We may use your information to measure*/}
            {/*            engagement with the Services and to understand how you and our other users interact*/}
            {/*            with and use the Services and other resources we provide.</li>*/}
            {/*        <li>&#8226; Improve customer service. Your information helps us to more effectively develop the*/}
            {/*            Services and respond to your support needs.</li>*/}
            {/*        <li>&#8226; Process transactions. We may use the information you provide to fulfill your requests.*/}
            {/*            We do not share this information with outside parties except to the extent necessary to*/}
            {/*            provide the Services and related activities.</li>*/}
            {/*        <li>&#8226; To send periodic communications. The communication methods you provide through*/}
            {/*            our contact forms will be used to send information and updates pertaining to the*/}
            {/*            Services. It may also be used to respond to your inquiries or other requests. If you opt*/}
            {/*            into our mailing list, you may receive emails that include Dupe news, updates, product*/}
            {/*            offerings and service information, and marketing material. If at any time you would like to*/}
            {/*            unsubscribe from receiving future emails, we include detailed unsubscribe instructions at*/}
            {/*            the bottom of each email or you may contact us via the contact information below.</li>*/}
            {/*        <li>&#8226; Carry out other legitimate business interests. We may use the information you*/}
            {/*            provide to carry out other legitimate business purposes, as well as other lawful*/}
            {/*            purposes.</li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>

    );
}

export default PrivacyPolicy

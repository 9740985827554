import axios from "axios";

export async function createCollection(collection, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections', collection, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function updateCollection(collection, token) {
    const result = await axios.put(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections', collection, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function deleteCollection(collectionId, token) {
    const result = await axios.delete(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getCollections(userId) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections?userId=' + userId)
    return result;
}

export async function getCollectionPreviews(userId, token, contentId) {
    let url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/preview?userId=' + userId;
    if (contentId) {
        url = url + "&contentId=" + contentId;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getPortfolioPreviews(userId, token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/portfolios/preview?userId=' + userId, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getCollectionContent(collectionId, page) {
    let url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId + '/content';
    if (page) {
        url = url + '?page=' + page;
    }
    const result = await axios.get(url)
    return result;
}

export async function createCollectionEntry(collectionId, collectionEntry, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId + "/entries", collectionEntry, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function updateCollectionCover(request, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/cover', request, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function deleteCollectionEntry(collectionEntryId, token) {
    const result = await axios.delete(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/entries/' + collectionEntryId, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function deleteContentFromCollection(collectionId, contentId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId + "/content/" + contentId
    const result = await axios.delete(url, { headers: {'X-CLIENT-ID': token}})
    return result;
}

import React, {useState} from 'react'
import '../stylesheets/LicenseTerms.css';


const Support = () => {

    return(
        <div className={"license-container"}>
            <div className={"license-header-div"}>
                <h3 className={"font-bold"}>Support</h3>
                <p>Reach out to support@dupephotos.com for any support related inquiries/requests</p>
            </div>
        </div>

    );
}

export default Support

import React, {useState} from 'react'
import '../stylesheets/AboutUs.css';
import '../stylesheets/AboutUs.mobile.css';
import {useNavigate} from "react-router-dom";


const AboutUs = () => {
    const navigate = useNavigate();

    return(
        <div className={"about-us-div"}>
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_1.svg"} />
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_2.svg"} />
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_3.svg"} />
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_4.svg"} />
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_5.svg"} />
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_6.svg"} />
            <img src={"https://dupe-photos.s3.amazonaws.com/about-us/dupe_about_DUPE_7.svg"} />
            <button className={"blue-btn font-bold im-in-btn"} onClick={event => navigate('/home')}>OK, I'M IN.</button>
        </div>

    );
}

export default AboutUs

import axios from "axios";

export async function uploadImage(formData, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/images', formData, { headers: {'Content-Type': 'multipart/form-data', 'X-CLIENT-ID': token}})
    return result;
}

export async function uploadPrivateImage(formData, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/images/private', formData, { headers: {'Content-Type': 'multipart/form-data', 'X-CLIENT-ID': token}})
    return result;
}

export async function getHomepageImages() {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/homepage/images')
    return result;
}

export async function uploadBusinessImage(formData, campaignId, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/creators/campaigns/' + campaignId + '/content', formData, { headers: {'Content-Type': 'multipart/form-data', 'X-CLIENT-ID': token}})
    return result;
}
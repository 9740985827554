import React, {useEffect, useState, useCallback} from "react";
import {Pressable, StyleSheet, Text, TextInput, View} from "react-native-web";
import {useNavigate} from "react-router-dom";
import {getCreatorProfile, saveCreator} from "../services/CreatorService";
import CustomCheckbox from "./CustomCheckbox";
import toast from "react-hot-toast";
import Select from 'react-select';
const notify = () => toast('Settings updated!', {
    duration: 2000,
    style: {
        fontFamily: "'lato', sans-serif",
    }
});


const ProfileSettings = () => {
    const navigate = useNavigate();
    const [isFeatureMeSelected, setFeatureMeSelected] = useState(false);
    const [isPhotoSelected, setPhotoSelected] = useState(false);
    const [isVideoSelected, setVideoSelected] = useState(false);
    const [isProductSelected, setProductSelected] = useState(false);
    const [isPaymentSelected, setPaymentSelected] = useState(false);
    const [country, setCountry] = useState(null);
    const [countryName, setCountryName] = useState("");
    const [region, setRegion] = useState(null);
    const [regionName, setRegionName] = useState("");
    const [bio, setBio] = useState("");
    const [preferredContactEmail, setPreferredContactEmail] = useState("");
    const [settingsChanged, setSettingsChanged] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    const json = require('country-region-data/data.json');
    const countryOptions = json.map(country => ({
        value: country.countryName,
        label: country.countryName,
        regions: country.regions.map(region => ({
            value: region.name,
            label: region.name
        }))
    }));

    const findCountryByName = (countryName) => {
        if (countryName === "" || countryName === null) return null;
        return countryOptions.find(country => country.value === countryName);
    };

    const findRegionByName = (regions, regionName) => {
        if (regionName === "" || regionName === null) return null;
        const foundRegion = regions.find(region => region.value === regionName);
        if (foundRegion) {
            return foundRegion;
        }
        return null;
    };

    function handleFeatureMeClicked() {
        setFeatureMeSelected(!isFeatureMeSelected)
        setSettingsChanged(true)
    }

    function handlePhotoSelected() {
        setPhotoSelected(!isPhotoSelected)
        setSettingsChanged(true)
    }

    function handleVideoSelected() {
        setVideoSelected(!isVideoSelected)
        setSettingsChanged(true)
    }

    function handleProductSelected() {
        setProductSelected(!isProductSelected)
        setSettingsChanged(true)
    }

    function handlePaymentSelected() {
        setPaymentSelected(!isPaymentSelected)
        setSettingsChanged(true)
    }

    function handleCountryChanged(country) {
        setCountry(country)
        setCountryName(country.value)
        const foundRegion = findRegionByName(country.regions, regionName)
        setRegion(foundRegion)
        setRegionName(foundRegion ? foundRegion.value : "")
        setSettingsChanged(true)
    }

    function handleRegionChanged(region) {
        setRegion(region)
        setRegionName(region.value)
        setSettingsChanged(true)
    }

    function handleBioChanged(bio) {
        setBio(bio)
        setSettingsChanged(true)
    }

    function handlePreferredContactEmailChanged(email) {
        setPreferredContactEmail(email)
        setSettingsChanged(true)
    }

    function isUpdateEnabled() {
        if (!settingsChanged) {
            return false;
        }

        //You can always update if you don't want to be visible to brands
        if (!isFeatureMeSelected) {
            return true;
        }

        //Required fields when you do want to be visible to brands
        return countryName !== "" && regionName !== "";
    }

    const handleUpdate = useCallback(() => {
        const token = localStorage.getItem("token");
        const storedUser = JSON.parse(localStorage.getItem("user"));

        const request = {
            creatorId: storedUser.id,
            available: isFeatureMeSelected,
            productCompensation: isProductSelected,
            paymentCompensation: isPaymentSelected,
            photo: isPhotoSelected,
            video: isVideoSelected,
            country: countryName,
            provenance: regionName,
            bio: bio,
            preferredContactEmail: preferredContactEmail
        };

        saveCreator(request, token)
            .then(() => {
                setSettingsChanged(false);
                notify();
            }).catch(err => {
            console.error(err);
        });
    }, [
        isFeatureMeSelected,
        isProductSelected,
        isPaymentSelected,
        isPhotoSelected,
        isVideoSelected,
        countryName,
        regionName,
        bio,
        preferredContactEmail
    ]);

    //On page load
    useEffect(() => {
        let active = true;
        const token = localStorage.getItem("token");

        setIsLoading(true);  // Set loading to true when starting the fetch

        getCreatorProfile(token)
            .then(resp => {
                if (active && resp.status === 200 && resp.data) {
                    setFeatureMeSelected(resp.data.available)
                    setProductSelected(resp.data.productCompensation)
                    setPaymentSelected(resp.data.paymentCompensation)
                    setVideoSelected(resp.data.video)
                    setPhotoSelected(resp.data.photo)
                    setBio(resp.data.bio)
                    setCountryName(resp.data.country)
                    const foundCountry = findCountryByName(resp.data.country)
                    setCountry(foundCountry)
                    if (foundCountry) {
                        setRegionName(resp.data.provenance)
                        setRegion(findRegionByName(foundCountry.regions, resp.data.provenance))
                    }
                    if (resp.data.preferredContactEmail) {  
                        setPreferredContactEmail(resp.data.preferredContactEmail)
                    } else {
                        const storedUser = JSON.parse(localStorage.getItem("user"));
                        setPreferredContactEmail(storedUser.email)
                    }
                }
            })
            .catch(err => {
                console.log(err)
                if (active && err.response.status === 401) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    navigate('/home');
                } else if (active && err.response.status === 400) {
                    console.log("No creator profile found")
                }
            })
            .finally(() => {
                if (active) {
                    setIsLoading(false);  // Set loading to false when fetch completes
                }
            });

        return () => {
            active = false;
        };
    }, [navigate]);

    if (isLoading) {
        return <View/>;
    }

    return (<View>
        <View style={{margin: 10}}>
            <Text style={styles.boldText}>Are you interested in connecting with brands for freelance content
                creation?</Text>
            <View style={styles.checkBoxContainer}>
                <View style={styles.checkBox}>
                    <CustomCheckbox
                        value={isFeatureMeSelected}
                        onValueChange={handleFeatureMeClicked}
                    />
                    <Text style={styles.text}> Yes, feature my Dupe profile on the Dupe Biz hiring page so brands
                        can find me</Text>
                </View>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={styles.subtleText}>Requests will be sent to your email</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={styles.subtleText}>by clicking yes, you are agreeing to our </Text>
                    <Text onClick={() => navigate('/license-and-terms')}
                          style={[styles.subtleText, {textDecorationLine: 'underline', cursor: 'pointer'}]}>Terms of
                        Service</Text>
                </View>
            </View>
        </View>
        {isFeatureMeSelected && <View>
            <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'column'}}>
                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What type of content do you shoot?</Text>
                        <View style={styles.checkBoxContainer}>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isPhotoSelected}
                                    onValueChange={handlePhotoSelected}
                                />
                                <Text style={styles.text}> Photo</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isVideoSelected}
                                    onValueChange={handleVideoSelected}
                                />
                                <Text style={styles.text}> Video</Text>
                            </View>
                        </View>
                    </View>

                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What Country are you based in?</Text>
                        <Select 
                            options={countryOptions} 
                            onChange={(option) => {handleCountryChanged(option)}} 
                            placeholder={countryName || "Select Country"}
                            value={country}
                            menuPortalTarget={document.body}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                    border: '1px solid #396DE0',
                                    borderRadius: '5px',
                                    backgroundColor: '#F1F1F1',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1px solid #396DE0'
                                    }
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                })
                            }}
                        />
                    </View>
                </View>

                <View style={{flexDirection: 'column'}}>
                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What type of compensation are you looking for?</Text>
                        <View style={styles.checkBoxContainer}>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isProductSelected}
                                    onValueChange={handleProductSelected}
                                />
                                <Text style={styles.text}> Product</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={isPaymentSelected}
                                    onValueChange={handlePaymentSelected}
                                />
                                <Text style={styles.text}> Payment</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <View style={{margin: 10, maxWidth: 200}}>
                            <Text style={styles.boldText}>Region</Text>
                            <Select 
                                options={country ? country.regions : []} 
                                onChange={(option) => {handleRegionChanged(option)}} 
                                placeholder={regionName || "Select Region"}
                                value={region}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                        border: '1px solid #396DE0',
                                        borderRadius: '5px',
                                        backgroundColor: '#F1F1F1',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            border: '1px solid #396DE0'
                                        }
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menuPortal: (provided) => ({
                                        ...provided,
                                        zIndex: 9999
                                    })
                                }}
                            />
                        </View>
                    </View>
                </View>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Preferred Contact Email</Text>
                <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                    <TextInput
                        onChangeText={text => handlePreferredContactEmailChanged(text)}
                        value={preferredContactEmail}
                        style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                    />
                </View>
                <Text style={styles.subtleText}>If you prefer to be contacted via an email address other than your Dupe account email, enter it here.</Text>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Bio</Text>
                <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                    <TextInput
                        multiline
                        rows={6}
                        maxLength={500}
                        onChangeText={text => handleBioChanged(text)}
                        value={bio}
                        style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                    />
                    <Text style={[styles.subtleText, {alignSelf: 'flex-end'}]}>
                        ({bio.length}/500)
                    </Text>
                </View>
                <Text style={styles.subtleText}>This is a great place to talk about what type of content you shoot,
                    your style, etc</Text>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Make sure your Instagram is added to your Dupe profile and set to a business or creator profile so brands can see your Instagram content.</Text>
            </View>

        </View>}
        <View>
            <Pressable style={!isUpdateEnabled() ? styles.updateButtonDisabled : styles.updateButton}
                        onPress={handleUpdate} disabled={!isUpdateEnabled()}>
                <Text style={styles.updateButtonText}>Update</Text>
            </Pressable>
        </View>
    </View>)
}

const styles = StyleSheet.create({
    boldText: {
        fontWeight: 900,
        fontFamily: "'lato', sans-serif",
        marginBottom: 5,
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
        marginTop: 5,
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    checkBox: {
        flexDirection: 'row',
        margin: 5,
    },
    updateButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 100,
        margin: 5,
        alignSelf: 'flex-end'
    },
    updateButtonDisabled: {
        borderRadius: 20,
        backgroundColor: '#A6A6A6',
        minWidth: 100,
        margin: 5,
        alignSelf: 'flex-end',
        cursor: 'default'
    },
    updateButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    text: {
        fontFamily: "'lato', sans-serif",
    },
})

export default ProfileSettings